import Vortex from "Lib/Vortex";
import routes from "./routes";
import loader from "./meta/loader";
import alert from "./meta/alert";
import "./stylesheets/main.css";

// Initializes jQuery prototypes.
require("./prototypes").default();

const app = new Vortex({
  name: "VortexFrontend",
  routes,
});

app.use(loader, alert);
app.init();
