export default {
  name: "loader",
  meta: {
    start: el => el.dispatchEvent(new Event("startLoading")),
    stop: el => el.dispatchEvent(new Event("stopLoading")),
    init() {
      /**
       * For use in the future.
        const html = `
          <div class="loader">
            <div class="d-flex justify-content-center align-items-center m-5 p-5">
              <img src="/images/flywheel_icon.png" class="mx-auto mb-3 spinning-image" style="max-width: 40px;">
            </div>
          </div>
        `
       */ 

      // Loader event handlers
      const loaderListeners = {
        ["startLoading"]: function(e) {
          replaceClass(e.target, ["load", "loading"]);
        },
        ["stopLoading"]: function(e) {
          replaceClass(e.target, ["loading", "loaded"]);
        }
      }

      // Collect list of loadables
      const loadables = Array.prototype.filter.call(
        document.getElementsByClassName("load"),
        el => typeof el.querySelector("loader") !== "undefined"
      );

      // Assign event listeners
      loadables.forEach(el => {
        Object.entries(loaderListeners)
          .forEach(([name, handler]) => {
            el.addEventListener(name, handler);
          });
      });
    }
  },
}
