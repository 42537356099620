/**
 * Routes
 * ======================================================================
 * Our application uses dynamic imports for code splitting and parses
 * the `paths` property of each route and exposes URL and query
 * parameters to each component.
 */

export default {
  audience: {
    paths: [ "/dataset_groups/:datasetGroupId/audiences/:id([0-9]+)", "/v2/dataset_groups/:datasetGroupId/audiences/:id([0-9]+)",
      "/embed/audiences/new",
      "/embed/audiences/:audience_id",
      "/embed/audiences/:audience_id/edit"],
    components: {
      AudienceExport: () => import("Components/audiences/exports/main"),
      ExportActivities : () =>
        import("Components/export-activities/main"),
      CampaignEvaluations : () =>
        import("@/components/audiences/campaign-evaluations/main"),
      ReviewComments : () =>
        import("Components/audiences/review-comments/main"),
      ZetaExport : () => import("Components/audiences/zeta-export/main"),
      FilterDescription : () =>
        import("Components/audiences/common/filter-description")
    }
  },
  audienceBuilder: {
    paths:
        [
          "/admin/datasets/:datasetId/audiences/:id/edit",
          "/admin/datasets/:datasetId/audiences/new",
          "/dataset_groups/:datasetGroupId/audiences/:id/edit",
          "/dataset_groups/:datasetGroupId/audiences/new",
        ],
    components: {
      AudienceBuilder: () => import("Components/audiences/builder/main")
    }
  },
  adminOrgTeams: {
    paths: [ "/admin/organizations/:organization_id/teams/:id/edit" ],
    components: {
      adminOrgTeams: () =>
        import("Components/admin/organizations/teams/main")
    }
  },
  adminOrgHiddenDestinations: {
    paths: [ "/admin/organizations/:organization_id/hidden_destinations" ],
    components: {
      adminOrgHiddenDestinations: () =>
        import("Components/admin/organizations/hidden-destinations/main")
    }
  },
  audiences: {
    paths: [ "/audiences", "/audience_templates" ],
    components: {
      Audiences: () => import("@/components/audiences/main"),
      ExportActivities : () =>
        import("Components/export-activities/main"),
      CreatorFilter : () => import("Components/audiences/index/filter/creator"),
      StatusFilter : () => import("Components/audiences/index/filter/status"),
      TagFilter : () => import("Components/audiences/index/filter/tag")
    }
  },
  organization: {
    paths:
        [
          "/organization",
        ],
    components: {
      Organization: () => import("Components/organizations/main"),
    }
  },
  reviewAudiences: {
    paths:
        [
          "/review_audiences",
        ],
    components: {
      ReviewAudiences: () => import("Components/review-audiences/main"),
    }
  },
  masterStores: {
    paths:
        [
          "/source_connections/:source_connection_id/datasets/new",
          "/source_connections/:source_connection_id/datasets/:id/edit",
          "/admin/source_connections/:source_connection_id/datasets/new",
          "/admin/source_connections/:source_connection_id/datasets/:id/edit"
        ],
    components: {
      MasterStores: () => import("Components/master-stores/main"),
      Datasets: () => import("Components/datasets/main")
    }
  },
  onboardingDataset: {
    paths: [ "/onboarding/datasets" ],
    components: {
      OnboardingDatasets: () => import("Components/onboarding/datasets"),
    }
  },
  onboardingSegmentations: {
    paths:
        [ "/onboarding/source_connections/:sourceConnectionId/segmentations" ],
    components: {
      OnboardingDatasets: () => import("Components/onboarding/segmentations"),
    }
  },
  onboardingDataWarehouses: {
    paths: [ "/onboarding/data_warehouses/new" ],
    components: {
      OnboardingDataWarehouses: () =>
        import("Components/onboarding/data-warehouses"),
    }
  },
  onboardingDataWarehousesDatasets: {
    paths: [ "/onboarding/data_warehouses/:data_warehouse_id/datasets" ],
    components: {
      OnboardingDataWarehousesDatasets: () =>
        import("Components/onboarding/data-warehouses/datasets"),
    }
  },
  googleAccountStaging: {
    paths: [ "/google_account_staging" ],
    components: {
      OnboardingDatasets: () =>
        import("Components/onboarding/google-account-staging"),
    }
  },
  sourceConnectionDatasets: {
    paths:
        [
          "/source_connections/:id/datasets/new",
          "/source_connections/:id/datasets/:dataset_id/edit",
        ],
    components:
        {SourceConnectionDatasets: () => import("Components/datasets/main")}
  },
  signalRoutes: {
    paths: ["/signal_routes/:signal_route_id/edit", "/signal_routes/new"],
    components: {
      ExportActivities: () =>
        import("Components/export-activities/main"),
      SignalRoute: () => import("Components/signal-routes/main")
    }
  },
  homePage: {
    paths: [ "/" ],
    components: {HomePage: () => import("Components/home-page/main")}
  }
}
