import $ from "jquery";

/**
 * For defining jQuery prototpes.
 */

export default function () {
  // Report slider prototype
  $.fn.reportSlider = function() {
    return this.each(function() {
      let $number1 = $(this).find(".number1");
      let $number2 = $(this).find(".number2");
      let $percent1 = $(this).find(".percent1");
      let $percent2 = $(this).find(".percent2");
      let $range = $(this).find("input[type='range'].range");
      let total = parseInt($range.attr("data-total"));

      $range.on("change", function() {
        total = parseInt($range.attr("data-total"));
        $percent1.text(this.value + "%");
        $percent2.text(100 - this.value + "%");
        $number1.text(
          numberWithCommas(Math.floor(total * parseInt(this.value) / 100))
        );
        $number2.text(
          numberWithCommas(
            total - Math.floor(total * parseInt(this.value) / 100)
          )
        );
      });
    });
  };

  // Text-width protype
  $.fn.textWidth = function(text, font) {
    if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = {};
    if (!$(this).attr("class")) return;
    const classes = $(this).attr("class").split(/\s+/);
    const id = $(this).prop("id");

    if (!$.fn.textWidth.fakeEl[id]) $.fn.textWidth.fakeEl[id] = $("<span>").hide().addClass(classes).appendTo(document.body);

    $.fn.textWidth.fakeEl[id].text(text || this.val() || this.text()).css("font", font || this.css("font"));
    return $.fn.textWidth.fakeEl[id].width() + 20;
  };
}