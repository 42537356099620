import { uuid } from "./utils"; 

export default class EventBus {
  constructor() {
    this.events = {};
  }

  subscribe = (type, handler) => {
    const ref = uuid();

    if (!this.events[type]) this.events[type] = {};

    this.events[type][ref] = handler;

    return {
      unsubscribe() {
        delete this.events[type][ref]

        if(!Object.keys(this.events[type]).length > 0) {
          delete this.events[type];
        }
      }
    }
  }

  emit = (type, opts) => {
    if (this.events[type]) {
      Object.keys(this.events[type])
        .forEach(ref => {
          const fn = this.events[type][ref];
          if (typeof fn === "function") {
            fn(opts)
          }
        });
    }
  }
}