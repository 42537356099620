import { getRule } from "./utils";
import EventBus from "./EventBus";

export default class Vortex {
  static eventBus = new EventBus();
  static meta = {};

  constructor({ name, routes }) {
    this.name = name;
    this.routes = routes;
    this.pathname = window.location.pathname;
  }

  use(...args) {
    for (const m of args) {
      if (m && m.meta) {
        Vortex.meta[m.name] = m.meta;
      }
    }
  }

  init() {
    for (const k in Vortex.meta) {
      const meta = Vortex.meta[k];
      if (meta && meta.init) meta.init();
    }

    Object.values(this.routes)
      .filter(route => {
        return route.paths.includes(this.pathname)
          || getRule(this.pathname, route.paths)
      })
      .forEach(({ components, paths }) => {
        for (const key in components) {
          const loader = components[key];

          loader().then(({ default: VortexComponent }) => {
            const component = new VortexComponent({ paths });
            if (component.render) component.render();
          }).catch(err => {
            throw err;
          });
        }
      })
  }
}
