// Destermines whether the DOM is ready.
export const DOMReady =
    document.readyState === "complete" || document.readyState === "interactive";

/**
 * Determines the equality of two arrays.
 */
export const arrayMatch =
    function(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    }

/**
 * Creates an object of parameters based on current pathname and rule.
 * i.e.: parseUrl("/audiences/3", "audiences/:id") => { id: 3 }
 */
export const getParams = (pathname, rule) => {
  if (!pathname || !rule) {
    return null;
  }
  const pathSegments = pathname.split("/");
  const ruleSegments = rule.split("/");
  const vectors =
      ruleSegments
        .map(
          (segment, i) => [segment, pathSegments[i]],
        )
        .filter(([ segment ]) => segment.includes(":"))
        .map(([ segment,
          slug ]) => { return {[segment.replace(":", "")] : slug}; });
  return Object.assign({}, ...vectors);
};

/**
 * Returns the appropriate rule from a list of rules for a given pathname.
 * Made to be used in conjunction with parseUrl.
 * i.e.: getRule("/audiences/3", ["/audiences/:id", "audiences"]) =>
 * "audiences/:id"
 */
export const getRule = (pathname, rules) => {
  if (!pathname || !rules || !Array.isArray(rules) ||
      !Boolean(rules.length > 0)) {
    return null;
  }
  // const patternMatch = /(?<!\()\/(?![\w\s]*[\)])/ this one was causing bugs
  // in safari with look behind. Removed it, seems to work great still and now
  // safari works
  const patternMatch = /\/(?![\w\s]*[\)])/
  const pathSegments = pathname.split(patternMatch);
  const exclude = (path, indices) =>
    path.filter((_, i) => { return !indices.includes(i); });

  const rule = rules.filter(
    (rule) => {
      const ruleSegments = rule.split(patternMatch);
      const params = ruleSegments.filter((s) => s.includes(":"));
      const indices = params.map((p) => ruleSegments.indexOf(p));

      for (const param of params) {
        if (param.match(/\((.*?)\)/g)) {
          const idx = ruleSegments.indexOf(param);
          const value = pathSegments[idx];
          const pathPattern = param.match(/\(([^)]+)\)/)[1];
          const regex = new RegExp(pathPattern)
          if (!regex || !pathPattern || !value) continue;

          if (!value.match(regex)) {
            return false;
          }
        }
      }
      return arrayMatch(
        exclude(pathSegments, indices),
        exclude(ruleSegments, indices),
      ) &&
               pathSegments.length === ruleSegments.length;
    },
  )[0];

  return rule && rule.length ? rule : null;
};

/**
 * Fuzzy matches a string with a string
 * (modified version of bevacqua/fuzzysearch)
 */
export function fuzzyMatch(needle, haystack) {
  const hlen = haystack.length;
  const nlen = needle.length;

  if (nlen > hlen) return false;
  if (nlen === hlen) return needle === haystack;

  outer: for (let i = 0, j = 0; i < nlen; i++) {
    const nch = needle.charCodeAt(i);
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) continue outer;
    }

    return false;
  }

  return true;
}

export function onlyOneCheckBox(checkBox, className) {
  const checkBoxes = document.querySelectorAll(className);

  checkBoxes.forEach((currentCheckBox) => {
    if (currentCheckBox !== checkBox) currentCheckBox.checked = false
  })
}

/**
 * Capitlaize String
 */
export function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

/**
 * Makes text safe for grep
 */
export function safeToGrep(str) {
  return str.trim().toLocaleLowerCase();
}

/**
 * Simple function for generating UUIDs.
 * Again, we are using this because lmiting third party libraries is essential.
 */
export function uuid() {
  return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(
      /[018]/g,
      c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4)
        .toString(16));
}

// Formats number to a string wtih commas.
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Hide all divs with a given class name
export function hideAll(className) {
  document.querySelectorAll(className).forEach(function(
    el) { el.style.display = "none"; });
}

export function formatDateMMDDYYYY(dateString) {
  const date = new Date(dateString);

  if (date.toString() === "Invalid Date" || typeof dateString === "number") {
    return "";
  } else {
    return date.toLocaleDateString("en-US");
  }
}

// Remove all options in a given dropdown
export function removeDropdownOptions(dropdown) {
  document.querySelectorAll(`#${dropdown.id} option`)
    .forEach(option => { option.remove() });
}

// Strips string of commas
export function stripCommas(x) { return x.toString().replace(/\,/g, ""); }

export function isOverflowed(el) {
  return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
}

export function getQueryStringByName(name) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(name);
}

// Checks if string is JSON
export function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getDatasetId() {
  const customerDetails = document.querySelector("#customer-details-dataset");

  if (customerDetails) {
    return customerDetails.dataset.customerDetailsId;
  }

  const filters = document.querySelector("#audience-builder-filters");
  if (!filters) {
    return;
  }
  const [form] = filters.querySelectorAll(".table-form");
  if (!form) {
    return;
  }

  return form.getAttribute("data-source-id");
}

export function whenReady(cb) {
  if (typeof cb !== "function") throw Error("Callback must be a function.");
  
  if (document.readyState === "complete" || document.readyState === "interactive") {
    return cb();
  }

  window.addEventListener("DOMContentLoaded", () => cb());
}
export function whenRegistered(elementName, callback) {
  // Check if the custom element is already registered
  if (customElements.get(elementName)) {
    // If registered, execute the callback immediately
    callback();
  } else {
    // If not registered, set up a MutationObserver to wait for its registration
    const observer = new MutationObserver((mutationsList, observer) => {
      // Check if the element is now registered
      if (customElements.get(elementName)) {
        observer.disconnect();  // Stop observing
        callback();  // Execute the callback
      }
    });

    // Start observing for changes in the document's custom elements registry
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  }
}
