export default {
  name: "alert",
  meta(message) {
    const delay = 6000;
    const slideSpeed = 200;
    const html = `
      <div class="alert alert-danger alert-dissmissible fade show fixed-top col-md-6 offset-md-3 mt-5" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <div class="text-center">
          ${message}
        </div>
      </div>
    `

    $("body").append(html);

    $(".alert")
      .delay(delay)
      .slideUp(slideSpeed, function() {
        $(this).alert("close");
      });
  }
}